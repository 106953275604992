//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { showLeaveGroupDialog } from '@/components/Modals/Default/Dialog/Presets'
import * as actionTypes from '@/store/actionTypes'
import { openHeadless } from '@/utils'
import { format } from 'quasar'
import {
  contactsStore,
  groupsStore,
  publicGroupsStore,
  teamsStore,
  uiStore,
  rootStore,
} from '@/store'
import { mapActions, mapGetters } from 'vuex'

const { capitalize } = format

export default {
  name: 'ChatMenu',

  props: {
    chatId: {
      type: String,
      required: true,
    },
    pinOption: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideOption: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSystembot: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      isElectron: window.isElectron ?? false,
      groupNotifications: false,
      systembotNotifications: false,
    }
  },

  computed: {
    ...mapGetters([
      'chatIsGroup',
      'chatPinnedState',
      'profile',
      'chatIsThread',
    ]),
    showGroupOptions () {
      return this.isGroup && this.group && !this.isForSneakpeek
    },
    isForSneakpeek () {
      if (!this.isGroup) return false
      return publicGroupsStore.state.sneakpeekId === this.chatId
    },
    groupNotificationsFromStore () {
      if (!this.isGroup) return false
      return groupsStore.getters.isNotificationsEnabled(this.chatId)
    },
    isGroup () {
      return this.chatIsGroup(this.chatId)
    },
    group () {
      return groupsStore.state.data[this.chatId]
    },
    isDev () {
      return window.FEATURES.is_testing
    },
    isThread () {
      return this.chatIsThread(this.chatId)
    },
    autodeleteOption () {
      const canChange = this.group.changeableFields.includes('autocleanup_age')
      const isSet = this.group.autocleanupAge !== null
      if (!canChange && !isSet) return null

      const age = this.group.autocleanupAge
      const minLen = 60
      const hourLen = minLen * 60
      const dayLen = hourLen * 24

      const captionData = []
      const days = Math.floor(age / dayLen)
      days > 0 && captionData.push(
        days + this.$t('common.day_short'),
      )
      const hours = Math.floor((age - days * dayLen) / hourLen)
      hours > 0 && captionData.push(
        hours + this.$t('common.hour_short'),
      )
      const minutes = Math.round((age - days * dayLen - hours * hourLen) / minLen)
      minutes > 0 && captionData.push(
        minutes + this.$t('common.minute_short'),
      )

      return {
        label: this.$t('modals.AutodeleteSettings.title'),
        active: canChange,
        caption: captionData.join(' '),
        action: () => {
          const instance = 'AutodeleteSettings'
          const payload = { chatId: this.chatId }
          uiStore.actions.showModal({ instance, payload })
        },
      }
    },
    hideChatOption () {
      if (!this.hideOption) return null

      const label = this.$t('chatlist.chatMenu.hideChat')
      const action = () => {
        this.TOGGLE_CHAT_EXCLUDED_STATE({
          chatId: this.chatId,
          exclude: true,
        })
      }
      return { label, action }
    },
    pinChatOption () {
      if (!this.pinOption) return null

      const isPinned = this.chatPinnedState(this.chatId)

      const label = isPinned
        ? this.$t('chatlist.chatMenu.unpinChat')
        : this.$t('chatlist.chatMenu.pinChat')
      const action = () => this.TOGGLE_CHAT_PIN_STATE({
        chatId: this.chatId,
        pinned: !isPinned,
      })
      return { label, action }
    },
    openProfileOption () {
      const label = capitalize(this.$t('glossary.profile'))
      const action = () => {
        let instance, payload
        if (this.isSingleGroupTeam()) {
          instance = 'team-profile'
          payload = teamsStore.state.currentTeamId
        } else {
          instance = this.isGroup ? 'group-profile' : 'contact-profile'
          payload = this.chatId
        }
        uiStore.actions.switchRightBarInstance({
          instance,
          payload,
        })
      }

      return { label, action }
    },
    openHeadlessOption () {
      return {
        label: this.$t('chattape.openInNewWindow'),
        action: () => openHeadless(this.chatId),
      }
    },
    groupSettingsOption () {
      const result = {
        label: this.$t('common.groupSettings'),
        action: this.editGroup,
      }

      if (this.group.canDelete) {
        return result
      }

      if (!this.group.changeableFields) {
        if (this.group.canChangeSettings) {
          return result
        }
      }

      // Проверяем есть ли у группы хотя бы одно и полей, которые есть в настройках
      const fields = ['display_name', 'description', 'icons', 'public', 'default_for_all', 'readonly_for_members']
      let count = 0

      for (let i = 0; i < fields.length; i++) {
        if (this.group.changeableFields.includes(fields[i])) {
          count++
        }
      }

      if (count > 0) {
        return result
      }
      return null
    },
    addMembersOption () {
      if (!this.group.canAddMember) return null

      return {
        label: this.$t('common.addMembers'),
        action: this.addMembers,
      }
    },
    leaveGroupOption () {
      if (this.isSingleGroupTeam()) return null

      return {
        label: this.$t('common.leaveGroup'),
        action: this.leaveGroup,
      }
    },
    notificationsOption () {
      return {
        label: capitalize(this.$t('glossary.notifications')),
        caption: this.$t('profiles.notifications.captionDisabled'),
        disable: !rootStore.state.isNotificationsAllowed,
        value: this.groupNotifications,
        action: this.onChangeGroupNotifications,
      }
    },
    countersOption () {
      return {
        label: this.$t('chattape.unreads'),
        value: !!this.group.countersEnabled,
        action: this.onChangeCountersEnabled,
      }
    },

    systembotNotificationsFromStore () {
      return this.profile.systembotNotificationsEnabled ?? false
    },
  },

  watch: {
    groupNotificationsFromStore: {
      immediate: true,
      handler (value) {
        this.groupNotifications = value
      },
    },
    systembotNotificationsFromStore: {
      immediate: true,
      handler (value) {
        this.systembotNotifications = value
      },
    },
  },

  methods: {
    ...mapActions([
      actionTypes.TOGGLE_CHAT_EXCLUDED_STATE,
      actionTypes.TOGGLE_CHAT_PIN_STATE,
    ]),
    isSingleGroupTeam (teamId) {
      return teamsStore.getters.isSingleGroupTeam(teamId)
    },
    editGroup () {
      uiStore.actions.showModal({
        instance: 'GroupForm',
        payload: { type: 'edit', jid: this.chatId },
      })
    },
    addMembers () {
      uiStore.actions.showModal({
        instance: 'all-members',
        payload: {
          jid: this.chatId,
          edit: true,
        },
      })
    },
    async leaveGroup () {
      showLeaveGroupDialog(this.chatId)
    },
    async onChangeCountersEnabled (value) {
      await groupsStore.actions.edit({
        groupId: this.chatId,
        payload: { counters_enabled: value },
      })
    },
    async onChangeGroupNotifications (value) {
      this.groupNotifications = value
      try {
        await groupsStore.actions.edit({
          groupId: this.chatId,
          payload: { notifications_enabled: value },
        })
      } catch (e) {
        // TODO: refactor this
        setTimeout(() => {
          this.groupNotifications = this.groupNotificationsFromStore
        }, 300)
        throw e
      }
    },
    async onChangeSystembotNotifications (value) {
      this.systembotNotifications = value

      if (value) {
        window.goal('chatHeaderMenu', { chatHeader: 'ВКЛ пуши Тадабот' })
      } else {
        window.goal('chatHeaderMenu', { chatHeader: 'ВЫКЛ пуши Тадабот' })
      }
      try {
        await contactsStore.actions.editContact({
          jid: this.profile.jid,
          params: { systembot_notifications_enabled: value },
        })
      } catch (e) {
      // TODO: refactor this
        setTimeout(() => {
          this.systembotNotifications = this.systembotNotificationsFromStore
        }, 300)
        throw e
      }
    },
  },
}
